<template>
  <div>
    <div class="block md:flex justify-center" v-if="post.user">
      <div class="w-full" :class="{
        'md:w-1/2': post.type === 2,
        'md:w-9/25': [1, 3, 4].includes(post.type)
      }">
        <t-card :variant="theme === 'dark' ? 'dark' : 'light'">
          <template v-slot:header>
            <div class="pb-2 px-6">
              <div class="w-full flex justify-between">
                <div class="flex">
                  <div class="w-16 h-16">
                    <profile-picture :data="post.user" :sizePx="128" />
                  </div>

                  <div class="ml-3 my-auto">
                    <p class="text-black dark:text-white text-sm sm:text-md" @click="openProfile(post.user)"
                      style="display: flex; align-items: center; cursor: pointer">
                      {{ post.user.username }}
                      <span class="ml-1" v-if="post.user.verified == 1">
                        <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                        <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                      </span>
                    </p>
                    <p class="text-gray-400 text-sm sm:text-md">
                      {{ post.created_at | fromNow }}
                    </p>
                    <p class="text-gray-400 text-sm sm:text-md"
                      v-if="loggedUser && post.user.id_role === 1 && loggedUser.id_role === 1">
                      {{
                          post.country ? post.country[$i18n.locale + "_name"] : ""
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:default>
            <img v-if="post.type === 1" :alt="post.text" class="mx-auto object-cover px-2 py-1"
              style="cursor: pointer; aspect-ratio: 1" src="/static/logo_square.png" :ref="'loading-picture'" />
            <template v-if="post.type === 4 && post.post_photos.length > 0">
              <div class="flex flex-col">
                <!-- 1 picture case -->

                <div class="mx-auto my-auto" v-if="post.post_photos.length === 1">
                  <img v-if="post.post_photos[0].url_picture" :alt="post.text"
                    class="mx-auto object-cover px-2 py-2 dynamic-aspect-ratio" src="/static/logo_square.png"
                    :ref="'loading-picture-poll-' + post.post_photos[0].id" />
                  <img :alt="post.text" v-if="post.post_photos[0].url_picture" style="border-radius: 15px"
                    class="hidden mx-auto object-cover px-2 py-2 dynamic-aspect-ratio"
                    @load="onPollImageLoad(post.post_photos[0].id)" :src="picture(post.post_photos[0])"
                    :ref="'poll-' + post.post_photos[0].id" />
                </div>
                <!-- 2 pictures case -->

                <div class="mx-auto w-full flex" v-if="post.post_photos.length === 2">
                  <div class="w-1/2 my-auto">
                    <img v-if="post.post_photos[0].url_picture" :alt="post.text"
                      class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                      :ref="'loading-picture-poll-' + post.post_photos[0].id" />
                    <img :alt="post.text" v-if="post.post_photos[0].url_picture" style="border-radius: 15px"
                      class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio"
                      @load="onPollImageLoad(post.post_photos[0].id)" :src="picture(post.post_photos[0])"
                      :ref="'poll-' + post.post_photos[0].id" />
                  </div>
                  <div class="w-1/2 my-auto">
                    <img v-if="post.post_photos[1].url_picture" :alt="post.text"
                      class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                      :ref="'loading-picture-poll-' + post.post_photos[1].id" />
                    <img :alt="post.text" v-if="post.post_photos[1].url_picture" style="border-radius: 15px"
                      class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio"
                      @load="onPollImageLoad(post.post_photos[1].id)" :src="picture(post.post_photos[1])"
                      :ref="'poll-' + post.post_photos[1].id" />
                  </div>
                </div>

                <!-- 3 pictures case -->

                <div class="mx-auto flex" v-if="post.post_photos.length === 3">
                  <div class="w-1/3 my-auto">
                    <img v-if="post.post_photos[0].url_picture" :alt="post.text"
                      class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                      :ref="'loading-picture-poll-' + post.post_photos[0].id" />
                    <img :alt="post.text" v-if="post.post_photos[0].url_picture"
                      class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                      @load="onPollImageLoad(post.post_photos[0].id)" :src="picture(post.post_photos[0])"
                      :ref="'poll-' + post.post_photos[0].id" />
                  </div>
                  <div class="w-1/3 my-auto">
                    <img v-if="post.post_photos[1].url_picture" :alt="post.text"
                      class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                      :ref="'loading-picture-poll-' + post.post_photos[1].id" />
                    <img :alt="post.text" v-if="post.post_photos[1].url_picture"
                      class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                      @load="onPollImageLoad(post.post_photos[1].id)" :src="picture(post.post_photos[1])"
                      :ref="'poll-' + post.post_photos[1].id" />
                  </div>
                  <div class="w-1/3 my-auto">
                    <img v-if="post.post_photos[2].url_picture" :alt="post.text"
                      class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                      :ref="'loading-picture-poll-' + post.post_photos[2].id" />
                    <img :alt="post.text" v-if="post.post_photos[2].url_picture"
                      class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                      @load="onPollImageLoad(post.post_photos[2].id)" :src="picture(post.post_photos[2])"
                      :ref="'poll-' + post.post_photos[2].id" />
                  </div>
                </div>

                <!-- 4 pictures case -->

                <div class="mx-auto w-full" v-if="post.post_photos.length === 4">
                  <div class="flex mx-auto">
                    <div class="w-1/2 my-auto">
                      <img v-if="post.post_photos[0].url_picture" :alt="post.text"
                        class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                        :ref="'loading-picture-poll-' + post.post_photos[0].id" />
                      <img :alt="post.text" v-if="post.post_photos[0].url_picture"
                        class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                        @load="onPollImageLoad(post.post_photos[0].id)" :src="picture(post.post_photos[0])"
                        :ref="'poll-' + post.post_photos[0].id" />
                    </div>
                    <div class="w-1/2 my-auto">
                      <img v-if="post.post_photos[1].url_picture" :alt="post.text"
                        class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                        :ref="'loading-picture-poll-' + post.post_photos[1].id" />
                      <img :alt="post.text" v-if="post.post_photos[1].url_picture"
                        class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                        @load="onPollImageLoad(post.post_photos[1].id)" :src="picture(post.post_photos[1])"
                        :ref="'poll-' + post.post_photos[1].id" />
                    </div>
                  </div>
                  <div class="flex mx-auto">
                    <div class="w-1/2 my-auto">
                      <img v-if="post.post_photos[2].url_picture" :alt="post.text"
                        class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                        :ref="'loading-picture-poll-' + post.post_photos[2].id" />
                      <img :alt="post.text" v-if="post.post_photos[2].url_picture"
                        class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                        @load="onPollImageLoad(post.post_photos[2].id)" :src="picture(post.post_photos[2])"
                        :ref="'poll-' + post.post_photos[2].id" />
                    </div>
                    <div class="w-1/2 my-auto">
                      <img v-if="post.post_photos[3].url_picture" :alt="post.text"
                        class="mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" src="/static/logo_square.png"
                        :ref="'loading-picture-poll-' + post.post_photos[3].id" />
                      <img :alt="post.text" v-if="post.post_photos[3].url_picture"
                        class="hidden mx-auto object-cover px-1 py-1 dynamic-aspect-ratio" style="border-radius: 10px"
                        @load="onPollImageLoad(post.post_photos[3].id)" :src="picture(post.post_photos[3])"
                        :ref="'poll-' + post.post_photos[3].id" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <iframe :id="'video-player-' + post.id" v-if="post.type === 2" width="100%" height="100%"
              class="mx-auto px-2 py-1" style="aspect-ratio: 16/9" :src="getYoutubeEmbedLink(post.url_video)"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            <video v-if="post.type === 5" class="mx-auto px-2 py-1" :src="post.imgkit_url_video || post.url_video"
              :style="post.aspect_ratio < 1 ? 'max-height: 65vh' : ''"
              @play="(evt) => handleVideoPlay(post.id, evt.target)" playsinline autoplay loop controls
              controlsList="nodownload"></video>
            <img :alt="post.text" v-if="post.type === 1" class="hidden mx-auto object-cover px-2 py-1"
              style="cursor: pointer; aspect-ratio: 1" @load="onImageLoad" :src="
                post.imgkit_url_picture
                  ? post.imgkit_url_picture
                  : post.url_picture
              " :ref="'post-' + post.id" />

            <div class="px-5" v-if="post.type === 3">
              <p class="text-black dark:text-white text-md sm:text-lg" v-html="$detectHashtagsAndUsernames(post.text)"
                @click="handleProcessedTextClick"></p>
            </div>
          </template>
          <template v-slot:footer>
            <div class="px-5" v-if="[1, 2].includes(post.type)">
              <p class="text-black dark:text-white text-sm sm:text-md" v-html="$detectHashtagsAndUsernames(post.text)"
                @click="handleProcessedTextClick"></p>
            </div>
            <template v-if="post.type === 4">
              <div v-for="pollElement in post.questions.slice()" :key="pollElement.question.id">
                <p class="text-black dark:text-white text-sm md:text-md my-2 font-bold">
                  {{ pollElement.question.question }}
                </p>
                <div v-for="answer in pollElement.answers" :key="answer.answer.id" class="mb-3">
                  <div class="flex">
                    <div class="bg-transparent w-11/12 rounded-lg relative flex justify-center border-primary-1100 py-3"
                      style="border-width: 1px" :style="!post.ended ? 'cursor:pointer' : ''" v-on="
                        !post.ended && loggedUser
                          ? {
                            click: () =>
                              answerPoll(
                                post,
                                pollElement.question.id,
                                answer.answer.id,
                                pollElement.answered_by_you
                              )
                          }
                          : {}
                      ">
                      <div class="absolute left-0 top-0 bg-primary-1100 rounded-lg h-full border-primary-1100"
                        style="z-index: 5" :style="'width:' + answer.percentage + '%;'"></div>
                      <p class="mx-auto text-center my-auto text-black dark:text-white text-sm md:text-md"
                        style="z-index: 10">
                        <img v-if="answer.selected_by_you" src="/static/icons/check.svg" alt="Checked con"
                          class="inline-block mx-auto dark:filter invert" style="cursor: pointer; width: 20px" />

                        {{ answer.answer.answer }}
                      </p>
                    </div>
                    <p class="text-center w-1/12 text-black dark:text-white text-sm md:text-md my-2 ml-1">
                      {{ answer.percentage }}%
                    </p>
                  </div>
                </div>
                <p class="text-center text-black dark:text-white text-sm md:text-md my-2 ml-1">
                  {{ pollElement.total_answers_for_question }}
                  {{ $i18n.t("votes") }}
                  {{ post.ended ? " - " + $i18n.t("poll_ended") : "" }}
                </p>
              </div>
            </template>

            <ActionBar :element="post" :allowLike="loggedUser && loggedUser.id"
              :calculateHighNumber="calculateHighNumber" @likePost="likePost" @openModal="openLikesModal"
              @sharePost="$emit('sharePost', element)"
              :id="loggedUser ? loggedUser.id : null" @openProfile="openProfileFromLikesModal" />
          </template>
        </t-card>
      </div>

      <div class="w-full md:w-2/5 flex flex-col content-between px-2 mt-2">
        <comments @openProfile="openProfile" :post="post" :showNewComment="true" :height="'78vh'"
          @commentDeleted="post.total_comments--" />
      </div>
    </div>
    <LikedByModal :modal="likesModal" :postID="postID" @closeModal="closeLikesModal"
      @openProfile="openProfileFromLikesModal" />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Comments from "@/components/post/comments.vue"
import Likes from "@/components/post/likes.vue"
import LikedByModal from "@/components/post/LikedByModal.vue"
import ActionBar from "@/components/post/ActionBar.vue"
import ProfilePicture from "@/components/user/ProfilePicture.vue"

export default {
  data() {
    return {
      post: {},
      commentText: "",
      likesModal: false,
      postID: 0,
      followListLoader: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    likesTextClasses() {
      if (!this.user) {
        return "text-sm hover:text-white"
      }
      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "text-sm text-white"
      if (this.user.followed_by_you_status === 1) return "text-sm text-white"
    },
    likesButtonClasses() {
      if (!this.user) {
        return "px-2 py-1 bg-transparent border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      }

      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      if (this.user.followed_by_you_status === 1)
        return "px-2 py-1 bg-transparent border border-gray-500 rounded shadow-sm hover:bg-gray-500"
    },
    theme() {
      return this.$store.getters["getTheme"]
    }
  },
  components: {
    ProfilePicture,
    Comments,
    Likes,
    LikedByModal,
    ActionBar
  },
  methods: {
    triggerSharePost() {
      this.$emit("sharePost", this.post)
    },
    openLikesModal(postID) {
      // this.followListLoader = true
      this.postID = postID
      this.likesModal = true
    },
    closeLikesModal() {
      this.likesModal = false
      this.postID = 0
    },
    openProfileFromLikesModal(user) {
      this.likesModal = false
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    openPostReport() {
      this.$emit("openPostReport", this.post)
    },
    picture(element) {
      let url
      if (element.imgkit_url_picture) {
        url = element.imgkit_url_picture
      } else {
        url = element.url_picture
      }
      return url
    },
    answerPoll(post, question_id, answer_id, answered) {
      if (answered)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "You already have a vote for this question"
            : "Ya tienes un voto para esta pregunta",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      if (post.ended)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "This poll does not allow new votes"
            : "Esta encuesta ya no permite nuevos votos",
          this.$i18n.locale === "en"
            ? "This poll has been closed"
            : "La encuesta fue cerrada",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      if (!this.pollLoading) {
        this.pollLoading = true
        if (!post.answered_poll)
          this.$store
            .dispatch("global/create", {
              payload: {
                id_user: this.loggedUser.id,
                id_poll: post.id,
                id_question: question_id,
                id_answer: answer_id
              },
              route: "/post/poll/answer/save",
              module: null
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification(
                  "poll_answer",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              if (response.code === 200) {
                let datax = { ...this.post }
                datax.questions = response.data.questions
                datax.answers = response.data.total_answers
                Object.assign(this.post, datax)
              }
              this.pollLoading = false
            })
            .catch((error) => {
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              this.pollLoading = false
            })
      }
    },
    onPollImageLoad(id) {
      if (this.$refs["loading-picture-poll-" + id])
        this.$refs["loading-picture-poll-" + id].classList.add("hidden")
      if (this.$refs["poll-" + id])
        this.$refs["poll-" + id].classList.remove("hidden")
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    openProfile(user) {
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .then((response) => { })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openProfile(user) {
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    getPost() {
      this.$store
        .dispatch("global/get", {
          route: `/post/user/${this.$route.params.username}/${this.$route.params.datetime}`,
          module: null,
        })
        .then((response) => {
          if (response.code === 200) {
            if (
              (!this.loggedUser || this.loggedUser.id_role !== 1) &&
              response.data.id_country !== 0 &&
              response.data.id_country !== this.loggedUser.id_country
            ) {
              this.$snotify.error(
                this.$i18n.t("post_invalid_country"),
                "Error",
                {
                  timeout: 5000,
                  showProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  titleMaxLength: 40,
                  bodyMaxLength: 100,
                  position: "rightBottom"
                }
              )
              return this.$router.go(-1)
            }
            this.post = response.data
            if (this.loggedUser)
              this.$socket.emit("post_viewed", {
                id_user: this.loggedUser.id,
                id_post: this.post.id
              })

            this.$nextTick(function () {
              if (this.post.type === 2) {
                window.handleVideoPlay = this.handleVideoPlay
                function onPlayerStateChange(event) {
                  let postId = event.target.h.id.split("-player-")[1]
                  let htmlId = event.target.h.id
                  if (event.data == YT.PlayerState.PLAYING) {
                    window.handleVideoPlay(
                      parseInt(postId),
                      document.getElementById(htmlId)
                    )
                  }
                }

                let postId = this.post.id
                const player = new YT.Player(`video-player-${postId}`, {
                  playerVars: {
                    origin: window.location.host,
                    widget_referrer: window.location.host
                  },
                  events: {
                    onStateChange: onPlayerStateChange
                  }
                })
                window["youtube-player-" + postId] = player
              }
            })
          } else {
            if (response.code === 404) return this.$router.push("/notfound")
            notify().then(({ notification }) => {
              notification(
                "error",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
          this.$emit("finishLoading")
        })
        .catch((error) => {
          console.log("Error", error)
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    onImageLoad() {
      if (this.$refs["loading-picture"])
        this.$refs["loading-picture"].classList.add("hidden")
      if (this.$refs["post-" + this.post.id])
        this.$refs["post-" + this.post.id].classList.remove("hidden")
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) > 1000) {
        return (number / 1000).toFixed(2) + "K"
      } else {
        return number
      }
    },
    getYoutubeEmbedLink(link) {
      let base_url = "https://www.youtube.com/embed/"
      if (link.includes("youtube")) {
        return (
          base_url +
          link.split("?v=")[1] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else if (link.includes("youtu.be")) {
        return (
          base_url +
          link.split("/")[3] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else {
        return ""
      }
    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    }
  },
  created() {
    this.$emit("startLoading")
    this.getPost()
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.getPost()
      }
    }
  }
}
</script>
