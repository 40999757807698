<template>
  <div>
    <div class="block md:flex justify-center">
      <Highlight :showDetails="true" :noAuth="false" :element="currentPost" :allowLike="true" :showComments="false" :allowComments="true"
        :allowSubscription="false" :OAuthPreferences="OAuthPreferences"
        :isLoadingSubscription="false" :calculateHighNumber="calculateHighNumber"
        @likePost="likePost" @toggleComments="() => {}" @openModal="openLikesModal"
        @sharePost="() => {}" @subscribeChannel="() => {}"
        :id="loggedUser ? loggedUser.id : null" @finishLoading="$emit('finishLoading')"
        @closeModal="closeLikesModal"
        @openProfile="openProfileFromLikesModal" :likesModal="likesModal" />
    </div>
  </div>
</template>

<script>

const notify = () => import("@/utils/notify.js")
import Highlight from "@/components/highlight/Highlight.vue"
import Comments from "@/components/post/comments.vue"

export default {
  data() {
    return {
      currentPost: {},
      commentText: "",
      likesModal: false,
      postID: 0,
      followListLoader: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    likesTextClasses() {
      if (!this.user) {
        return "text-sm hover:text-white"
      }
      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "text-sm text-white"
      if (this.user.followed_by_you_status === 1) return "text-sm text-white"
    },
    likesButtonClasses() {
      if (!this.user) {
        return "px-2 py-1 bg-transparent border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      }

      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      if (this.user.followed_by_you_status === 1)
        return "px-2 py-1 bg-transparent border border-gray-500 rounded shadow-sm hover:bg-gray-500"
    },
    theme() {
      return this.$store.getters["getTheme"]
    }
  },
  components: {
    Comments,
    Highlight
  },
  methods: {
    openLikesModal(postID) {
      // this.followListLoader = true
      this.postID = postID
      this.likesModal = true
    },
    closeLikesModal() {
      this.likesModal = false
      this.postID = 0
    },
    openProfileFromLikesModal(user) {
      this.likesModal = false
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    openProfile(user) {
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .then((response) => { })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) == 1000000) return "1M"
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) == 1000) return "1K"
      if (Number(number) > 1000) return (number / 1000).toFixed(2) + "K"

      return number
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.84) / 255)
    },
    openProfile(user) {
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
  },
  created() {
    this.$emit("startLoading")
  },
}
</script>
