<template>
  <div class="flex">
    <div class="w-full">
      <div class="p-5 md:p-3">
        <carousel v-if="hashtags.length > 0" :nav="false" :dots="false" class="carousel-card relative" :margin="1"
          :responsive="{
            0: { items: 1, nav: false },
            340: { items: 2, nav: false },
            650: { items: 3, nav: false },
            800: { items: 3, nav: false }
          }">
          <t-button v-for="hashtag in hashtags" :key="'hashtag-' + hashtag.id" variant="hashtag" class="mx-2"
            :class="activeHashtag === hashtag.id ? 'bg-gray-500 dark:bg-gray-400' : ''"
            @click="filterByHashtag(hashtag)">
            {{ hashtag.name ? hashtag.name : hashtag }}</t-button>
          <template slot="prev">
            <ArrowCarousel direction="left" />
          </template>
          <template slot="next">
            <ArrowCarousel direction="right" />
          </template>
        </carousel>
      </div>

      <div v-for="group in videosPosts" :key="'video-group-' + group.hashtag.id" class="mb-5 px-5 md:px-3">
        <p class="text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3">
          {{ group.hashtag.name ? group.hashtag.name : group.hashtag }}
        </p>
        <carousel :nav="false" :dots="false" class="carousel-card video-carousel relative" :margin="15" :responsive="{
          0: { items: 1, nav: false },
          340: { items: 1, nav: false },
          650: { items: 2, nav: false },
          800: { items: 4, nav: false }
        }" style="background-color: transparent;">
          <video-preview v-for="video in group.videos" :key="`video-${video.id}`" :element="video" :user="video.user"
            @clickVideo="openPost(video)" />

          <template slot="prev">
            <ArrowCarousel direction="left" />
          </template>
          <template slot="next">
            <ArrowCarousel direction="right" />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
import VideoPreview from "@/components/gallery/VideoPreview.vue"
import { debounce } from "lodash"
import ArrowCarousel from "../components/arrowCarousel.vue"
const notify = () => import("@/utils/notify.js")

export default {
  data() {
    return {
      activeHashtag: null,
      hashtags: [],
      itemsAmount: Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    videosPosts() {
      if (!this.activeHashtag) {
        return this.$store.getters["post/getAllGrouped"]
      } else {
        return this.$store.getters["post/getAllGrouped"].filter(
          (x) => x.hashtag.id === this.activeHashtag
        )
      }
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    suggestedVideos() {
      return this.$store.getters["post/getAllSuggestedVideos"]
    },
    smallFontSize() {
      return "0.80rem"
    }
  },
  props: {
    postModal: {
      type: Boolean,
      default: false
    },
    openPostWithEmit: {
      type: Boolean,
      default: false
    },
    currentPost: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    carousel,
    VideoPreview,
    ArrowCarousel
  },
  methods: {
    onImageLoad(id) {
      if (this.$refs["loading-picture-" + id][0])
        this.$refs["loading-picture-" + id][0].classList.add("hidden")
      if (this.$refs["video-preview-" + id][0])
        this.$refs["video-preview-" + id][0].classList.remove("hidden")
    },
    filterByHashtag(hashtag) {
      if (this.activeHashtag === hashtag.id) {
        this.activeHashtag = null
      } else {
        this.activeHashtag = hashtag.id
      }
    },
    openPost(post) {
      if (this.openPostWithEmit) return this.$emit('openPost', post)
      this.$emit("update:currentPost", post)
      this.$emit("update:postModal", true)
      this.$socket.emit("post_viewed", {
        id_user: this.loggedUser.id,
        id_post: post.id
      })
    },
    getVideosPosts() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/hashtag/grouped",
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setGroupedElements", response.data)
          let hashtags = response.data.map((x) => x.hashtag)

          this.hashtags =
            hashtags.length > 10 ? hashtags.slice(0, 10) : hashtags


          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getSuggestedVideos() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/top_10/2/" + this.loggedUser.id,
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setSuggestedVideos", response.data)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    hi(e) {
      console.log('hola')
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  created() {
    this.$emit("startLoading")
    this.getVideosPosts()
    this.getSuggestedVideos()
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },

  watch: {}
}
</script>
<style>
.carousel-card .owl-nav {
  display: none;
}

.video-carousel .owl-carousel .owl-item {
  width: 274px !important;
}

/*
.active-hashtag {
  @apply bg-gray-500 dark: bg-gray-400;
}
*/
</style>
