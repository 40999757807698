<template>
  <div>
    <div class="block md:flex justify-between" v-if="post.user">
      <div class="w-full md:w-2/3 lg:3/5 xl:4/5">
        <post-mode-element :element="post" @likedPost="likedPost" :user="{
          ...post.user,
          followed_by_you_status: post.followed_by_you_status
        }" :canEdit="
  post.user && post.user.id === loggedUser.id ? true : false
" @postDeleted="handlePostDeletion" @userUpdate="handleUserUpdate" @commentDeleted="commentDeleted"
          @openPostReport="openPostReport" @updatePoll="updatePoll" @endPoll="endPoll" @sharePost="triggerSharePost" />
      </div>

      <div v-if="loggedUser" class="w-full md:w-1/3 lg:2/5 xl:1/5 flex flex-col content-between px-2 mt-2">
        <comments @openProfile="openProfile" :post="post" :showNewComment="!!loggedUser" :height="'78vh'"
          @commentDeleted="post.total_comments--" />
      </div>
    </div>

    <LikedByModal v-if="loggedUser" :modal="likesModal" :postID="postID" @closeModal="closeLikesModal"
      @openProfile="openProfileFromLikesModal" />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Comments from "@/components/post/comments.vue"
import PostModeElement from "@/components/gallery/PostModeElement.vue"
import Likes from "@/components/post/likes.vue"
import LikedByModal from "@/components/post/LikedByModal.vue"
import ActionBar from "@/components/post/ActionBar.vue"
import ProfilePicture from "@/components/user/ProfilePicture.vue"

export default {
  data() {
    return {
      post: {},
      commentText: "",
      likesModal: false,
      postID: 0,
      followListLoader: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    likesTextClasses() {
      if (!this.user) {
        return "text-sm hover:text-white"
      }
      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "text-sm text-white"
      if (this.user.followed_by_you_status === 1) return "text-sm text-white"
    },
    likesButtonClasses() {
      if (!this.user) {
        return "px-2 py-1 bg-transparent border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      }

      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      if (this.user.followed_by_you_status === 1)
        return "px-2 py-1 bg-transparent border border-gray-500 rounded shadow-sm hover:bg-gray-500"
    },
    theme() {
      return this.$store.getters["getTheme"]
    }
  },
  components: {
    ProfilePicture,
    PostModeElement,
    Comments,
    Likes,
    LikedByModal,
    ActionBar
  },
  methods: {
    likedPost(post) {
      this.post = post
    },
    handlePostDeletion(post) {
      this.$router.push("/home")
    },
    handleUserUpdate({ post, user }) {
      this.post.user = user
      this.post.followed_by_you_status = user.followed_by_you_status
    },
    commentDeleted(comment) {
      this.post.total_comments--
    },
    updatePoll(data) {
      console.log(data)
      let datax = this.post
      datax.questions = data.questions
      datax.answers = data.total_answers
      data.key = new Date()

      Object.assign(this.post, datax)
    },
    endPoll(data) {
      Object.assign(this.post, {
        ...this.post,
        ended: 1
      })
    },
    triggerSharePost() {
      this.$emit("sharePost", this.post)
    },
    openLikesModal(postID) {
      // this.followListLoader = true
      this.postID = postID
      this.likesModal = true
    },
    closeLikesModal() {
      this.likesModal = false
      this.postID = 0
    },
    openProfileFromLikesModal(user) {
      this.likesModal = false
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    openPostReport() {
      this.$emit("openPostReport", this.post)
    },
    picture(element) {
      let url
      if (element.imgkit_url_picture) {
        url = element.imgkit_url_picture
      } else {
        url = element.url_picture
      }
      return url
    },
    answerPoll(post, question_id, answer_id, answered) {
      if (answered)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "You already have a vote for this question"
            : "Ya tienes un voto para esta pregunta",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      if (post.ended)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "This poll does not allow new votes"
            : "Esta encuesta ya no permite nuevos votos",
          this.$i18n.locale === "en"
            ? "This poll has been closed"
            : "La encuesta fue cerrada",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      if (!this.pollLoading) {
        this.pollLoading = true
        if (!post.answered_poll)
          this.$store
            .dispatch("global/create", {
              payload: {
                id_user: this.loggedUser.id,
                id_poll: post.id,
                id_question: question_id,
                id_answer: answer_id
              },
              route: "/post/poll/answer/save",
              module: null
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification(
                  "poll_answer",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              if (response.code === 200) {
                let datax = { ...this.post }
                datax.questions = response.data.questions
                datax.answers = response.data.total_answers
                Object.assign(this.post, datax)
              }
              this.pollLoading = false
            })
            .catch((error) => {
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              this.pollLoading = false
            })
      }
    },
    onPollImageLoad(id) {
      if (this.$refs["loading-picture-poll-" + id])
        this.$refs["loading-picture-poll-" + id].classList.add("hidden")
      if (this.$refs["poll-" + id])
        this.$refs["poll-" + id].classList.remove("hidden")
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].id)
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    openProfile(user) {
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .then((response) => { })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openProfile(user) {
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    getPost() {
      console.log("POST ID: ", this.$route.params.id)

      this.$store
        .dispatch("global/getItem", {
          route: "/post",
          module: null,
          id: this.$route.params.id
        })
        .then((response) => {
          if (response.code === 200) {
            if (
              this.loggedUser && this.loggedUser.id_role !== 1 &&
              response.data.id_country !== 0 &&
              response.data.id_country !== this.loggedUser.id_country
            ) {
              this.$snotify.error(
                this.$i18n.t("post_invalid_country"),
                "Error",
                {
                  timeout: 5000,
                  showProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  titleMaxLength: 40,
                  bodyMaxLength: 100,
                  position: "rightBottom"
                }
              )
              return this.$router.go(-1)
            }

            console.log("POST: ", this.post)
            this.post = response.data

            if (this.loggedUser) {
              this.$socket.emit("post_viewed", {
                id_user: this.loggedUser.id,
                id_post: this.post.id
              })
            }

            this.$nextTick(function () {
              if (this.post.type === 2) {
                window.handleVideoPlay = this.handleVideoPlay
                function onPlayerStateChange(event) {
                  let postId = event.target.h.id.split("-player-")[1]
                  let htmlId = event.target.h.id
                  if (event.data == YT.PlayerState.PLAYING) {
                    window.handleVideoPlay(
                      parseInt(postId),
                      document.getElementById(htmlId)
                    )
                  }
                }

                let postId = this.post.id
                const player = new YT.Player(`video-player-${postId}`, {
                  playerVars: {
                    origin: window.location.host,
                    widget_referrer: window.location.host
                  },
                  events: {
                    onStateChange: onPlayerStateChange
                  }
                })
                window["youtube-player-" + postId] = player
              }
            })
          } else {
            if (response.code === 404) return this.$router.push("/notfound")
            notify().then(({ notification }) => {
              notification(
                "error",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
          this.$emit("finishLoading")
        })
        .catch((error) => {
          console.log("Error", error)
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    onImageLoad() {
      if (this.$refs["loading-picture"])
        this.$refs["loading-picture"].classList.add("hidden")
      if (this.$refs["post-" + this.post.id])
        this.$refs["post-" + this.post.id].classList.remove("hidden")
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) > 1000) {
        return (number / 1000).toFixed(2) + "K"
      } else {
        return number
      }
    },
    getYoutubeEmbedLink(link) {
      let base_url = "https://www.youtube.com/embed/"
      if (link.includes("youtube")) {
        return (
          base_url +
          link.split("?v=")[1] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else if (link.includes("youtu.be")) {
        return (
          base_url +
          link.split("/")[3] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else {
        return ""
      }
    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    }
  },
  created() {
    this.$emit("startLoading")
    this.getPost()
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.$emit("startLoading")
        this.getPost()
      }
    }
  }
}
</script>
