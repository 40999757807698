<template>
  <div>
    <div>
      <t-button variant="empty" @click="$router.go(-1)"
        class="mr-2 px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
        style="height: fit-content">
        <span class="text-sm text-white">{{ $i18n.t('back') }}</span>
      </t-button>


      <div class="w-full flex flex-col justify-center items-center pt-4 px-2">
        <div style=" min-height: 7vh; top: 11vh"
          class="w-full bg-jevi dark:bg-jevi-dark px-8 py-2 z-10 rounded-lg block md:flex justify-between items-center text-center sticky ">
          <div class="text-center" style="width: 20%">
          </div>
          <div class="text-center">
            <span class='text-primary-1100 font-bold post-hashtag' style="font-size: 20px">#{{ $route.params.id }}
            </span>
          </div>
          <div class="text-center" :style="hashtag ? '' : 'width: 20%'">
            <t-button v-if="!!hashtag" variant="empty" @click="toggleFollow()" :class="followButtonClasses(hashtag)"
              style="text-align: center; height: fit-content; min-width: 20%;">
              <span :class="followTextClasses" v-if="!hashtagLoader">{{ hashtag.hashtag_followed_by_you === 0 ?
                  $i18n.t("follow")
                  : ""
              }}{{ hashtag.hashtag_followed_by_you === 1 ? $i18n.t("stop_following") : ""
}}</span>
              <span v-if="hashtagLoader">
                <t-loader extraClass="border-orange-primary mt-1" /></span>
            </t-button>
          </div>
        </div>

        <div v-if="!this.hashtag" class="text-center mt-5 mx-auto w-full sm:w-4/5 md:w-1/2">
          <span class='text-center text-white' style="font-size: 14px">{{ $t('no_more_posts') }}</span>
        </div>

        <div v-else class="mt-5 mx-auto w-full sm:w-4/5 md:w-1/2">

          <post-mode-element v-for="element in posts" :element="element" :key="element.id" :user="{
            ...element.user,
            followed_by_you_status: element.followed_by_you_status
          }" :canEdit="
  element.user && element.user.id === loggedUser.id ? true : false
" @postDeleted="handlePostDeletion" @userUpdate="handleUserUpdate" @likedPost="likedPost"
            @commentDeleted="commentDeleted" @openPostReport="openPostReport" @sharePost="sharePost" />
        </div>

      </div>
    </div>
  </div>
</template>

<!--
<template>
  <div>
    <t-button variant="empty" @click="$router.go(-1)"
      class="mr-2 px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200"
      style="height: fit-content">
      <span class="text-sm text-white">{{ $i18n.t('back') }}</span>
    </t-button>

    <div class="w-full flex" style="overflow-x: hidden;">
      <div class="w-full sm:w-8/12 lg:w-4/6 xl:w-9/12 flex flex-col justify-center items-center pt-4 px-2">
        <div style="min-height: 7vh; top: 11vh"
          class="w-full bg-jevi dark:bg-jevi-dark px-8 py-2 z-10 rounded-lg block md:flex justify-between items-center text-center sticky ">
          <div class="text-center" style="width: 20%">
          </div>
          <div class="text-center">
            <span class='text-primary-1100 font-bold post-hashtag' style="font-size: 20px">#{{ $route.params.id }}
            </span>
          </div>
          <div class="text-center" :style="hashtag ? '' : 'width: 20%'">
            <t-button v-if="!!hashtag" variant="empty" @click="toggleFollow()" :class="followButtonClasses(hashtag)"
              style="text-align: center; height: fit-content; min-width: 20%;">
              <span :class="followTextClasses" v-if="!hashtagLoader">{{ hashtag.hashtag_followed_by_you === 0 ?
                  $i18n.t("follow")
                  : ""
              }}{{ hashtag.hashtag_followed_by_you === 1 ? $i18n.t("stop_following") : ""
}}</span>
              <span v-if="hashtagLoader">
                <t-loader extraClass="border-orange-primary mt-1" /></span>
            </t-button>
          </div>
        </div>

        <div v-if="!this.hashtag" class="text-center mt-5 mx-auto w-full sm:w-4/5 md:w-1/2">
          <span class='text-center text-white' style="font-size: 14px">{{ $t('no_more_posts') }}</span>
        </div>

        <div v-else class="mt-5 mx-auto w-full sm:w-4/5 md:w-1/2">

          <post-mode-element v-for="element in posts" :element="element" :key="element.id" :user="{
            ...element.user,
            followed_by_you_status: element.followed_by_you_status
          }" :canEdit="
  element.user && element.user.id === loggedUser.id ? true : false
" @postDeleted="handlePostDeletion" @userUpdate="handleUserUpdate" @commentDeleted="commentDeleted"
            @openPostReport="openPostReport" @sharePost="sharePost" />
        </div>
      </div>

      <div class="overflow-y-scroll hidden sm:block lg:w-2/6 xl:3/12 h-full fixed right-0 mr-2 px-4">
        <div v-if="!top10HashtagsLoading" :variant="theme === 'dark' ? 'dark' : 'light'"
          class="suggestions-sidebar w-full ">
          <div class="pb-24">
            <p class="mt-3 mb-2 text-black dark:text-white px-2 text-center font-bold">
              TOP 10 HASHTAGS
            </p>

            <div class="w-full pb-5 grid grid-flow-row-dense md:grid-cols-1 sm:grid-cols-1 sm:gap-1 gap-2">
              <div v-for="(hashtag, index) in hashtags_top10" :key="hashtag.id" class="mx-5 flex justify-between"
                style="min-height: 10px;">
                <p class="text-sm" :class="theme === 'dark' ? 'text-white' : 'text-white'">
                  <span :class="theme === 'dark' ? 'text-white' : 'text-white'">{{ (index + 1) + '. ' }} </span>
                  <span class='text-primary-1100 font-bold post-hashtag' style="cursor: pointer;"
                    @click="openPostsByHashtag(hashtag)">{{ hashtag.name }} </span>
                </p>

                <p class="text-sm" :class="theme === 'dark' ? 'text-white' : 'text-white'">
                  <span :class="theme === 'dark' ? 'text-white' : 'text-white'">{{ hashtag.total_posts }} {{
                      hashtag.total_posts == 1 ? 'post' : 'posts'
                  }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <img v-else class="w-1/2 md:w-1/6 lg:w-1/5 mx-auto mt-24" :src="
  theme === 'dark'
    ? '/static/loading_darkmode.gif'
    : '/static/loading_lightmode.gif'
        " alt="loading-gif" />
      </div>
    </div>
  </div>
</template>
-->

<script>
import PostModeElement from "@/components/gallery/PostModeElement.vue"
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      posts: [],
      hashtag: null,
      hashtagLoader: false,
      top10HashtagsLoading: true,
      hashtags_top10: [],
    }
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    followTextClasses() {
      return "text-sm text-white";
    },
  },
  components: {
    PostModeElement
  },
  methods: {
    likedPost(post) {
      const idx = this.post.map(p => p.id).indexOf(post.id)
      this.post[idx] = post
    },
    sharePost(post) {
      this.$emit("sharePost", post)
    },
    followButtonClasses(post) {
      if (post.hashtag_followed_by_you === 0)
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
      else if (post.hashtag_followed_by_you === 1)
        return "px-2 py-1 bg-red-500 border border-red-500 rounded shadow-sm hover:bg-red-500";
      else
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
    },
    toggleFollow() {
      if (!this.hashtag || this.hashtag.hashtag_followed_by_you === 3) return;
      this.hashtagLoader = true;
      this.$store
        .dispatch("global/create", {
          payload: {
            id_hashtag: this.hashtag.id,
            id_user_sent: this.loggedUser.id
          },
          route: "/follow_hashtag/save",
          module: null,
        })
        .then((response) => {
          this.hashtagLoader = false;
          let hashtagx = this.hashtag;

          if (response.code == 200) {
            this.$socket.emit("join_topics", {
              id_user: this.loggedUser.id,
            });

            if (!response.data.active || response.data.active === 1) {
              hashtagx.hashtag_followed_by_you = 1;
            }
            else {
              hashtagx.hashtag_followed_by_you = 0;
            }
          }
          else {
            hashtagx.hashtag_followed_by_you = 0;
          }

          this.hashtag = hashtagx
        })
        .catch((error) => {
          console.log("follow error:", error);
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
          this.hashtagLoader = false;
        });
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    commentDeleted(comment) {
      let index = this.posts.findIndex((x) => x.id === comment.id_post)
      if (index !== -1) this.posts[index].total_comments--
    },
    handlePostDeletion(post) {
      let index = this.posts.findIndex((x) => x.id === post.id)
      if (index !== -1) {
        this.posts.splice(index, 1)
      }
    },
    handleUserUpdate({ post, user }) {
      let index = this.posts.findIndex((x) => x.id === post.id)
      if (index !== -1) {
        this.posts[index].user = user
        this.posts[index].followed_by_you_status = user.followed_by_you_status
      }
    },
    getPosts() {

      this.$store
        .dispatch("global/getItems", {
          route: "/hashtag/post/all",
          module: null,
          noPaginate: true,
          isMaster: true,
          params: {
            order_key: "created_at",
            order_value: "desc",
            id_hashtag: this.hashtag.id,
            active: 1
          }
        })
        .then((response) => {
          if (response.data.length === 0) return this.$router.push('/nopost')
          this.hashtag.hashtag_followed_by_you = response.data[0].hashtag_followed_by_you
          this.posts = response.data.map((x) => x.post)
          this.$emit("finishLoading")

          const arePublicPost = this.posts.some(post => post.user.some(p => p.private === 0))
          if (arePublicPost) return this.$router.push('/nopost')
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getHashtag() {
      this.$store
        .dispatch("global/getItem", {
          route: "/master/hashtag/name",
          module: "user",
          id: this.$route.params.id
        })
        .then((response) => {
          if (response.code == 200) {
            this.hashtag = response.data
            this.getPosts()
            if (this.hashtag.length === 0) { $router.push({ name: 'notfound' }) }
          }
          else {
            this.hashtag = null
            $router.push({ name: 'notfound' })
          }
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openPostsByHashtag(hashtag) {
      this.$router.push("/post_by_hashtag/" + hashtag.name.slice(1))
    },
    getHashtagsTop10() {
      this.$store
        .dispatch("global/getItems", {
          route: "/hashtag/all",
          module: null,
          noPaginate: true,
          isMaster: true,
          params: {
            order_key: "total_posts",
            order_value: "desc",
            limit: 10,
            active: 1
          }
        })
        .then((response) => {
          this.top10HashtagsLoading = false
          this.hashtags_top10 = response.data
        })
        .catch((error) => {
          this.top10HashtagsLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
  },
  created() {
    this.$emit("startLoading")
    this.getHashtag()
    this.getHashtagsTop10()
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.$emit("startLoading")
        this.getHashtag()
      }
    },

  }
}
</script>
