<template>
  <div class="w-full flex flex-col justify-start" :key="tab">
    <div class="w-full flex justify-between mb-3">
      <div class="px-3 py-2 flex justify-center" style="cursor: pointer; min-width: 33%;"
        :style="tab === 1 ? 'border-bottom: none' : 'border-bottom: solid 1px grey'" @click="tab = 1">
        <span>
          <img src="/static/icons/cut.svg" :class="
            tab === 1
              ? 'inline-block sidebar-image active'
              : 'inline-block sidebar-image dark:filter invert'
          " style="width: 26px; height: 26px; object-fit: cover" alt="icon" />
        </span>
      </div>

      <div class="px-3 py-2 flex justify-center" style="cursor: pointer; min-width: 34%;"
        :style="tab === 2 ? 'border-bottom: none' : 'border-bottom: solid 1px grey'" @click="tab = 2">
        <span>
          <img src="/static/icons/originals.svg" :class="
            tab === 2
              ? 'inline-block sidebar-image active'
              : 'inline-block sidebar-image dark:filter invert'
          " style="width: 26px; height: 26px; object-fit: cover" alt="icon" />
        </span>
      </div>

      <div class="px-3 py-2 flex justify-center" style="cursor: pointer; min-width: 33%;"
        :style="tab === 3 ? 'border-bottom: none' : 'border-bottom: solid 1px grey'" @click="tab = 3">
        <span>
          <img src="/static/icons/video.svg" :class="
            tab === 3
              ? 'inline-block sidebar-image active'
              : 'inline-block sidebar-image dark:filter invert'
          " style="width: 26px; height: 26px; object-fit: cover" alt="icon" />
        </span>
      </div>
    </div>

    <div v-if="tab === 1" class="w-full">
      <template>
        <Cuts :currentPost.sync="currentPost" :openPostWithEmit="true" @openPost="openPost"
          :postModal.sync="postModal" />

      </template>
    </div>

    <div v-else-if="tab === 2" class="w-full">
      <template>
        <Originals :currentPost.sync="currentPost" :openPostWithEmit="true" @openPost="openPost"
          :postModal.sync="postModal" />


      </template>
    </div>

    <div v-if="tab === 3" class="w-full">
      <template>
        <Videos :currentPost.sync="currentPost" :openPostWithEmit="true" @openPost="openPost"
          :postModal.sync="postModal" />
      </template>
    </div>
  </div>
</template>

<script>

import carousel from "vue-owl-carousel"
import VideoPreview from "@/components/gallery/VideoPreview.vue"
import Videos from "./tabs/Videos.vue"
import Cuts from "./tabs/Cuts.vue"
import Originals from "./tabs/Originals.vue"
import { debounce } from "lodash"
const notify = () => import("@/utils/notify.js")

export default {
  data() {
    return {
      tab: 1,
      activeHashtag: null,
      hashtags: [],
      hashtags2: [],
      hashtags3: [],
      itemsAmount: Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    groupedPosts() {
      if (!this.activeHashtag) {
        return this.$store.getters["post/getAllGrouped"]
      } else {
        return this.$store.getters["post/getAllGrouped"].filter(
          (x) => x.hashtag.id === this.activeHashtag
        )
      }
    },
    groupedPosts2() {
      if (!this.activeHashtag) {
        return this.$store.getters["post/getAllGrouped2"]
      } else {
        return this.$store.getters["post/getAllGrouped2"].filter(
          (x) => x.hashtag.id === this.activeHashtag
        )
      }
    },
    groupedPosts3() {
      if (!this.activeHashtag) {
        return this.$store.getters["post/getAllGrouped3"]
      } else {
        return this.$store.getters["post/getAllGrouped3"].filter(
          (x) => x.hashtag.id === this.activeHashtag
        )
      }
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    suggestedVideos() {
      return this.$store.getters["post/getAllSuggestedVideos"]
    },
    smallFontSize() {
      return "0.80rem"
    }
  },
  props: {
    postModal: {
      type: Boolean,
      default: false
    },
    currentPost: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    carousel,
    VideoPreview,
    Videos,
    Cuts,
    Originals,
  },
  methods: {
    onImageLoad(id) {
      if (this.$refs["loading-picture-" + id][0])
        this.$refs["loading-picture-" + id][0].classList.add("hidden")
      if (this.$refs["video-preview-" + id][0])
        this.$refs["video-preview-" + id][0].classList.remove("hidden")
    },
    filterByHashtag(hashtag) {
      if (this.activeHashtag === hashtag.id) {
        this.activeHashtag = null
      } else {
        this.activeHashtag = hashtag.id
      }
    },
    openPost(post) {
      this.$emit("update:currentPost", post)
      this.$emit("update:postModal", true)
      this.$socket.emit("post_viewed", {
        id_user: this.loggedUser.id,
        id_post: post.id
      })
    },
    getGroupedPosts() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/hashtag/grouped",
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setGroupedElements", response.data)
          let hashtags = response.data.map((x) => x.hashtag)
          this.hashtags =
            hashtags.length > 10 ? hashtags.slice(0, 10) : hashtags
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getGroupedPosts2() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/originals/all",
          module: null,
          noPaginate: true,
          params: {
            active: 1,
            order_key: 'updated_at',
            order_value: 'desc',
            page: 1,
            limit: 20,
            type: 5,
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setGroupedElements2", response.data.data)
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getGroupedPosts3() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/hashtag/grouped_new",
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setGroupedElements3", response.data)
          let hashtags = response.data.map((x) => x.hashtag)
          this.hashtags3 =
            hashtags.length > 10 ? hashtags.slice(0, 10) : hashtags
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getSuggestedVideos() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/top_10/2/" + this.loggedUser.id,
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setSuggestedVideos", response.data)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  created() {
    this.$emit("startLoading")
    this.getGroupedPosts()
    this.getGroupedPosts2()
    this.getGroupedPosts3()
    //this.getSuggestedVideos()
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },

  watch: {}
}
</script>
<style>
.carousel-card .owl-nav {
  display: none;
}

.video-carousel .owl-carousel .owl-item {
  width: 274px !important;
}

/*
.active-hashtag {
  @apply bg-gray-500 dark: bg-gray-400;
}
*/
</style>
